@import 'scss/bootstrap-variables';

.pathway-questions-container {
  position: relative;
  flex-grow: 1;
  height: 100%;
  .emergency {
    background-color: $red;
  }
  .warning {
    background-color: #f89636;
    color: #1e233e;
  }
  .warning-count-arrow-container {
    color: $white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    right: 0;
    left: 0;
    z-index: 1;
    min-height: 100px;
    transition: opacity 200ms;
    opacity: 0;
    &.arrow-container-above {
      top: 0;
    }
    &.arrow-container-below {
      bottom: 75px;
    }
    &.show {
      opacity: 1;
    }
    .warning-count-arrow {
      position: absolute;
      width: 44px;
      height: 24px;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      .counter {
        line-height: 100%;
        margin-right: 5px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 2px;
      }
    }
  }
}
.pathway-questions-list {
  overflow-y: scroll;
  padding-bottom: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .category-section {
    .category-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 40px 0 0 40px;
      .category-title {
        font-family: Roboto, sans-serif;
        font-size: 1rem;
        font-weight: 900;
        letter-spacing: 2px;
        margin: 0 25px 0 0;
      }
    }
  }
  .question-container {
    position: relative;
    padding: 30px 0 30px 40px;
    margin-right: 5%;
    border-bottom: solid 2px #eeeeee;
    .dependent-question {
      margin-top: 40px;
    }
  }

  .questions-footer {
    display: flex;
    flex-direction: row;
    min-height: 100px;
    width: 100%;
    margin-top: auto;
    background-color: $primary-contrast;
    padding: 30px 40px;
    justify-content: flex-end;
    .collapse-btn {
      display: flex;
      cursor: pointer;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      border-radius: 32px;
      border: solid 2px $primary-main;
      font-size: 0.84rem;
      font-weight: bold;
      letter-spacing: 1.69px;
      color: $primary-main;
      background-color: white;
      white-space: nowrap;
    }
  }
}
