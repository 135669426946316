@import 'scss/bootstrap-variables';

.enrollment-status-button {
  height: 100%;
  text-align: right;
  align-items: center;
  gap: 6px;
  font-size: 0.85rem;
  width: 100%;
  flex: 0;

  .status {
    color: $primary-main;
    font-weight: 500;
    font-size: 14px;
  }

  &.selected {
    & .status,
    & .icon-wrapper {
      color: $text-primary;
    }
  }

  &.dark,
  .extra {
    color: $text-inactive;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    &.dark,
    .status,
    .icon-wrapper {
      color: $primary-dark;
    }
  }

  .light {
    color: $gray-500;
  }

  .icon-wrapper {
    min-width: 12px;
    color: $primary-main;
  }
}
