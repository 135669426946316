@import 'scss/bootstrap-variables';

.styled-container {
  border-radius: 16px;
  background-color: #ffffff;
  border: solid 0.5px $gray-light;

  &.shadowed {
    box-shadow: 0 1px 4px 0 #e0e2e4;
  }
}
