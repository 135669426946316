@import 'scss/bootstrap-variables';

.report-section {
  padding: 20px 0;
  &.highlight {
    border-radius: 16px;
    padding: 20px;
    background-color: $primary-alternate;
    .tickets-list {
      padding-bottom: 0;
      .card-row {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.open-tickets-section {
  transition: margin-bottom 0.3s ease-in-out;

  &.is-collapsed {
    margin-bottom: 40px;
  }
}

.report-section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  color: $text-primary;
}
