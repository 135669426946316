@import 'scss/bootstrap-variables';

.styled-input {
  .input-area {
    input,
    textarea {
      border-radius: 0 16px 16px 16px;

      &.form-control:focus {
        border: 1px solid $primary-main;
        box-shadow: none;
      }
    }

    input,
    textarea {
      height: 45px;
      border: solid 1px $gray-light;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4;
      color: $text-primary;
      &:not(:disabled) {
        background-color: #ffffff;
      }
      // when refactoring according to design system, the following lines will
      // be replaced with inputStyle function. see input's shared.styles.ts
      &:not(:disabled):focus {
        box-shadow: none;
        border: 1px solid $primary-main;
      }

      &::placeholder {
        color: $text-inactive;
      }
    }

    textarea {
      min-height: 90px;
      resize: none;
    }

    .hint {
      font-size: 12px;
      padding: 3px;
      opacity: 0.6;
      font-style: italic;
    }
  }

  &.rounded-borders {
    .input-area {
      input,
      textarea {
        border-radius: 16px;
      }
    }
  }

  &.error {
    .input-header {
      color: $red;
    }
    .input-area {
      position: relative;
      input,
      textarea {
        border: solid 1px $red;
        &:focus {
          border-color: $red;
        }
      }
    }
    &.show-outside-error {
      .error-text {
        position: static;
        padding: 5px 0;
        font-weight: 400;
      }
    }
  }

  &.disabled {
    .input-area {
      input,
      textarea {
        background-color: $inactive-background;
        border-color: $border;
        color: #00000060;
      }
    }
  }
}
