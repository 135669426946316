.distress-cause-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 10px;

  .cause-and-alerts-container {
    display: flex;
    align-items: center;

    .cause-container {
      display: flex;
      align-items: center;

      .cause-name {
        white-space: nowrap;
      }
    }
  }
}

.comma-separator {
  padding: 5px 2px;
}
