@import 'scss/bootstrap-variables';
@import 'scss/mixins';

.styled-select {
  .select-header {
    font-size: 14px;
    font-weight: 500;
    color: $text-inactive;
    margin-bottom: 4px;
    display: flex;
    line-height: 16px;
  }

  .styled-select__indicator-separator {
    display: none;
  }

  .selection-area-container {
    &:focus {
      border: none;
    }

    .error-text {
      position: absolute;
      display: none;
      align-items: center;
      left: 1rem;
      top: 0;
      bottom: 0;
      margin: 0;
      color: $red;
    }

    .styled-select__control {
      font-size: 16px;
      font-weight: 400;
      color: $text-primary;

      .styled-select__placeholder {
        color: $text-inactive;
      }

      .styled-select__dropdown-indicator {
        border: none;
      }

      .styled-select__single-value {
        color: $text-primary;

        &--is-disabled {
          color: #00000060;
        }

        & .text-bold {
          font-weight: 500;
        }
      }

      .styled-select__indicator {
        padding: 4px;
      }

      .styled-select__value-container {
        & .text-bold {
          font-weight: 500;
        }
      }

      .styled-select__value-container--is-multi {
        .styled-select__multi-value {
          border-radius: 16px;
          line-height: 1.88;
          border: none;
          display: flex;
          align-items: center;
        }
      }
    }

    .rounded {
      .styled-select__control {
        border-radius: 15px 15px 15px 15px;
      }
    }

    .styled-select__menu {
      z-index: 2;
    }

    .styled-select__control--is-focused,
    .styled-select__control--menu-is-open {
      box-shadow: none;
    }

    .styled-select--is-disabled {
      .styled-select__control {
        background-color: $inactive-background;
        color: $text-inactive;

        .Select-value {
          background-color: $inactive-background !important;
          color: $text-inactive;

          .Select-value-label {
            color: $text-inactive !important;
          }
        }
      }

      .styled-select__multi-value {
        background-color: $primary-hover;
      }

      .styled-select__multi-value__label {
        color: #00000060;
      }

      .styled-select__dropdown-indicator {
        border: none;
      }

      .select-value-summary {
        color: #00000060;
      }
    }
  }

  &.error {
    .select-header {
      color: $red;
    }

    .selection-area-container {
      position: relative;

      .styled-select__control {
        border: solid 1px $red;
        color: $red;
      }

      .error-text {
        display: flex;
        pointer-events: none;
      }
    }
  }

  .empty-value-container {
    height: 0;
  }

  .select-value-summary {
    color: $primary-main;
    padding-left: 1em;
    cursor: pointer;
    align-self: center;

    &.secondary {
      color: $secondary-main;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &.secondary {
    .styled-select__label {
      span {
        transition: all 0.3s ease-in;
      }
    }

    &:has(.styled-select__label:hover, .styled-select__label:focus, .styled-select__label:active, .styled-select__control:hover, .styled-select__control--is-focused, .styled-select__control--menu-is-open) {
      .styled-select__label span {
        color: $secondary-main;
      }

      .selection-area-container .styled-select__control {
        border-color: $secondary-light-50;
        background-color: white;
      }
    }
  }
}
