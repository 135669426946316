@import 'scss/bootstrap-variables';

.report-sections {
  .report-section.highlight {
    margin-top: 40px;
  }
  .report-section:first-child {
    margin-top: 60px;
  }
}

.ticket-filters.page-filters {
  .styled-select .styled-select__multi-value__label {
    // keep muli-select filters in a single line. see https://expain.atlassian.net/browse/EH-3722
    max-width: 118px;
  }
}

.dashboard-view {
  .limited-width {
    position: relative;
    .refresh-btn {
      position: absolute;
      top: 4px;
      right: 0;
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    .search {
      flex: 1;
      margin-right: 21px;
    }
    .filters {
      margin-left: auto;
      position: relative;
    }
    .tooltip-text {
      top: 80%;
      left: 40%;
    }
    .patients-toggle {
      position: relative;
      margin-left: 21px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .toggle-container {
        height: 50px;
      }
      .patients-dropdown {
        max-height: 450px;
        overflow-y: scroll;
      }
    }
  }
}
