@import 'bootstrap';
@import 'scss/bootstrap-variables';

.chip-list {
  display: flex;
  flex-wrap: wrap;
  &.truncated {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    .chip {
      display: inline-block;
    }
  }

  .chip {
    color: $text-primary;
    font-size: 14px;
    padding: 1px 5px;
    line-height: 19px;
    border-radius: 3px;
    display: flex;
    border: 1px solid $gray-light;
    @extend .mr-2;
    @extend .align-items-center;

    &.borderless {
      border: none;
      padding: 0 5px;
    }

    &.bold {
      font-weight: 500;
    }

    &:last-child {
      @extend .mr-0;
    }

    &.chip-default {
      color: $text-primary;
      background-color: $white;
    }
    &.chip-color1 {
      background-color: $episode-tag-1;
    }
    &.chip-color2 {
      background-color: $episode-tag-2;
    }
    &.chip-color3 {
      background-color: $episode-tag-3;
      color: $white;
    }
    &.chip-color4 {
      background-color: $episode-tag-4;
    }
    &.chip-color5 {
      background-color: $episode-tag-5;
      color: $white;
    }
    &.chip-color6 {
      background-color: $episode-tag-6;
      color: $white;
    }
    &.chip-color7 {
      background-color: $episode-tag-7;
      color: $white;
    }
    &.chip-color8 {
      background-color: $episode-tag-8;
      color: $white;
    }
    &.chip-color9 {
      background-color: $episode-tag-9;
      color: $white;
    }
    &.chip-color10 {
      background-color: $episode-tag-10;
      color: $white;
    }
  }
}
