@import 'scss/bootstrap-variables';

.alerts-page {
  .header {
    margin: 55px 0;
    display: block;

    .title {
      font-size: 24px;
      line-height: 30px;
      color: $black;
    }

    .subtitle {
      font-size: 16px;
      line-height: 30px;
      font-weight: 400;
    }
  }

  .disclaimer {
    color: $gray;
    font-size: 16px;
    font-weight: bold;
  }

  .alerts-section {
    margin-bottom: 4rem;
    h4 {
      margin: 0;
    }
    .section-header {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
    }
    .section-title {
      display: flex;
      align-items: center;
      cursor: pointer;
      flex: 1;
    }
    .blue-white-table {
      margin: 0;
      margin-top: 40px;
    }
  }
  .no-alerts-placeholder {
    padding: 30px;
    color: $gray;
    border: 1px solid $gray-light;
    margin-bottom: 0;
    margin-top: 53px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 16px;
    opacity: 0.7;
  }
}
