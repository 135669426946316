.fixed-loader {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 12px;
  backdrop-filter: blur(1px);
  &.background {
    background: #6c757d17;
  }
}
