@import 'scss/bootstrap-variables';

.question {
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 1.125rem;
  color: #000000;
  .title {
    font-weight: bold;
    display: flex;
    flex-direction: row;
  }
  .subtitle {
    font-size: 1.25rem;
  }
  .date-answer {
    margin-top: 15px;
    max-width: 200px;
  }

  .text-answer {
    margin-top: 15px;
    .styled-input .input-area textarea {
      resize: vertical;
      border-radius: 0.5rem;
    }
  }

  .text-array-container {
    display: flex;
    flex-direction: column;
    .text-array-answer {
      margin-top: 15px;
      .option-alert {
        color: $warnings-dark;
        font-weight: 500;
      }
      .styled-select__control {
        border-radius: 0.5rem;
      }
    }
    .dx-disclaimer {
      color: $text-inactive;
      font-size: 12px;
      margin-top: 2px;
    }
  }

  .selection-area-container,
  .select-container {
    margin-top: 15px;
  }

  .select-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 400;
    color: $text-primary;
    cursor: pointer;
    &:hover {
      .check-area {
        background-color: $primary-alternate;
      }
    }
    .check-area {
      width: 22px;
      height: 21px;
      margin-right: 15px;
      border: solid 1px $primary-main;
      &.circle {
        border-radius: 50%;
        &.selected {
          background-color: $primary-main;
        }
      }
    }

    ul {
      padding: 0.3rem 1.5rem;
      margin: 0;
    }
  }
  .warning-label {
    padding: 3px 10px;
    margin-left: auto;
    font-size: 1rem;
    white-space: nowrap;
    height: min-content;
    font-weight: 900;
    letter-spacing: 2px;
    color: #ffffff;
    border-radius: 4px;

    .dx-alert {
      max-width: 553px;
      padding: 2px 10px 0;
    }
  }
}
