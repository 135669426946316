@import 'scss/mixins';
@import 'src/scss/bootstrap-variables';

.dropdown-option {
  font-size: 1rem;

  .dropdown-option-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .edit-button {
      font-size: 0.84375rem;
      color: $primary-main;
      cursor: pointer;
    }
  }
}
