@import 'scss/bootstrap-variables';
@import 'scss/mixins';

.styled-dropdown {
  width: 100%;
  position: relative;
  cursor: default;

  .dropdown-label {
    font-size: 14px;
    font-weight: 500;
    color: $text-inactive;
    margin-bottom: 4px;
    display: flex;
    line-height: 16px;

    &.error {
      color: $red;
    }
  }

  .dropdown-head-container {
    position: relative;
    height: 45px;
    border: solid 1px $gray-light;
    background-color: $white;
    border-radius: 0 16px 16px 16px;
    padding: 6px 14px;
    font-weight: 500;
    line-height: 1.88;
    font-size: 1rem;
    color: #000000;
    display: flex;
    align-items: center;

    &.rounded-borders {
      border-radius: 16px;
    }

    &.small-dropdown-head {
      height: 31px;
    }

    p {
      margin-bottom: 0;
      font-weight: 400;
      color: $text-primary;
    }

    .placeholder {
      color: $text-inactive;
      font-weight: 400;
    }

    .error-text {
      position: absolute;
      display: none;
      align-items: center;
      left: 14px;
      top: 0;
      bottom: 0;
      margin: 0;
      color: $red;
    }

    &:before {
      position: absolute;
      right: 0.75rem;
      margin-top: auto;
      margin-bottom: auto;
      content: url('../../img/icons/down-arrow-purple.svg');
      transition: transform 200ms;
    }

    &:disabled {
      background-color: white;
    }
  }

  .dropdown-container {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
    padding: 4px 0;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 #e0e2e4;
    border: solid 1px $gray-light;
    background-color: $white;

    &.open-up {
      top: auto;
      bottom: 54px;
    }

    .dropdown-option {
      padding: 8px 28px 8px 10px;
      border: none;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      .option-checkmark {
        min-width: 14px;
        margin-right: 4px;
        visibility: hidden;
      }
      &.disabled-option {
        color: $text-inactive;
      }

      &:not(.disabled-option) {
        &:hover,
        &:focus {
          color: $text-primary;
          background-color: $primary-hover;
        }
        &.selected {
          &:hover,
          &:focus {
            color: $primary-main;
            background-color: $primary-hover;
          }
          .option-checkmark {
            visibility: visible;
          }
          color: $primary-main;
        }
      }
    }
  }
  &.error {
    .dropdown-head-container {
      border: solid 1px $red;
      color: $red;

      .error-text {
        position: absolute;
        display: flex;
        align-items: center;
        left: 14px;
        top: 0;
        bottom: 0;
        margin: 0;
        color: $red;
      }

      &.small-dropdown-head {
        border: solid 1px $red;

        .error-text {
          left: initial;
        }

        &:before {
          content: url('../../img/icons/down-arrow-red.svg');
        }
      }
    }
  }

  &.closed {
    .dropdown-container {
      display: none;
    }
  }

  &.disabled {
    .dropdown-head-container {
      background-color: $inactive-background;
      p {
        color: $text-inactive;
      }
      &:before {
        content: url('../../img/icons/down-arrow-gray.svg');
      }
    }
  }
  &.open {
    .dropdown-head-container {
      border: 1px solid $primary-main;

      &.rounded-borders {
        border-radius: 16px 16px 0 0;
      }
      &:before {
        transform: rotate(-180deg);
        transform-origin: 50% 50%;
      }

      & .placeholder {
        color: $primary-main;
      }
    }
  }
}
