@import 'scss/bootstrap-variables';

.icon-button {
  display: flex;
  color: $primary-main;
  font-size: 16px;
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;

  &:disabled {
    color: $gray; // currrently not effective - color detemined by icon (see EH-2384)
    cursor: initial;
  }
}
