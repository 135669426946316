@import 'scss/bootstrap-variables';
.table-top-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 0 20px 4px;

  .expand-all-btn {
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13.5px;
    font-weight: bold;
    color: $gray-label;
  }
}
.table-bottom-container {
  padding: 20px 0 4px;
}
