@import 'scss/bootstrap-variables';
@import 'scss/mixins';

.expandable-row-view {
  margin-bottom: 24px;
  p {
    margin: 0;
    font-size: 0.84rem;
  }
  .expandable-row-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    .title {
      font-size: 1rem;
      font-weight: 500;
    }
    .expand-icon {
      color: $primary-main;
    }
  }

  .expandable-row-body {
    margin-top: 12px;
    margin-left: 16px;
  }
  .expandable-row-body {
    display: none;
  }
  &.expand {
    .expandable-row-body {
      display: block;
    }
  }
}
