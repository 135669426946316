@import 'scss/bootstrap-variables';
@import '../../../components/Tooltip/Tooltip';

.pathway-view-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  transition: left 500ms, width 500ms;
  .side-tab {
    font-size: 0.84rem;
    font-weight: bold;
    letter-spacing: 1.69px;
    transform: rotate(-90deg);
    transform-origin: 0 0;
    position: absolute;
    padding: 2px 10px;
    top: 50%;
    left: -24px;
    border: solid 1px $gray-light;
    border-bottom: none;
    background-color: #ffffff;
    white-space: nowrap;
    color: $primary-main;
    .side-tab-icon {
      display: inline-block;
      transform: rotate(90deg);
      margin-right: 10px;
    }
  }
  .pathways-content-box {
    width: 100%;
    height: 100%;
    border: solid 1px $gray-light;
    background-color: white;
    .header-container {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      padding: 15px 40px;
      width: 100%;
      transition: opacity 200ms;
      border-radius: 16px 0 0 0;
      border-bottom: solid 1px $gray-light;
      .key-questions-toggle-container {
        margin-left: auto;
        .square-toggle-item {
          width: 124px;
        }
      }
      .header {
        position: relative;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25rem;
        font-weight: 500;
        i {
          padding-top: 6px;
        }
        color: #000000;
      }
    }
    .pathways-content {
      display: flex;
      flex-direction: row;
      height: 100%;
      .table-of-content-section {
        display: flex;
        flex-direction: row;

        &.closed {
          > :first-child {
            padding: 0;
            opacity: 0;
            transition: width 500ms 200ms, opacity 200ms, padding 500ms;
            width: 0;
            pointer-events: none;
          }
          .sections-divider {
            &:after {
              left: -5px;
              transform: rotate(0deg);
              transform-origin: center center;
            }
          }
        }
        &.open {
          > :first-child {
            opacity: 1;
            transition: width 500ms, opacity 200ms 500ms, padding 500ms;
            width: 300px;
            padding: 13px 30px 160px 20px;
          }
          .sections-divider {
            .expand-btn {
              left: -16px;
              transform-origin: center center;
              transform: rotate(-180deg);
              &:hover {
                transform: rotate(-180deg) scale(1.1);
              }
            }
          }
        }
        .sections-divider {
          min-height: 100%;
          border: solid 1px $gray-light;
          position: relative;
          z-index: 1;
          .expand-btn {
            position: absolute;
            cursor: pointer;
            top: 23px;
            left: -8px;
            transition: 300ms;
            content: url('../../../img/icons/collapse-index.svg');
            &:hover {
              content: url('../../../img/icons/collapse-index-active.svg');
              transform: rotate(0) scale(1.1);
            }
          }
        }
      }
    }
  }

  &.open {
    width: 100%;
    left: 0;
    .header-container {
      opacity: 1;
      transition-delay: 200ms;
      .header {
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .pathways-content {
      opacity: 1;
      transition: opacity 500ms;
      transition-delay: 500ms;
    }
  }
  &.closed {
    cursor: pointer;
    width: 20px;
    left: 20px;
    .header-container {
      opacity: 0;
    }
    &:hover {
      left: 10px;
    }
    .pathways-content-box {
      transition: border-left-color, border-right-color, border-top-color, left;
      transition-delay: 500ms;
    }
    .pathways-content {
      opacity: 0;
      transition: opacity 300ms;
    }
  }
}

.pathway-tooltip-close-btn {
  border-top: solid 1px #dedede;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  border-radius: 0 0 $tooltip-radius $tooltip-radius;

  &:hover {
    background: #dedede;
  }
}
