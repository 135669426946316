@import '../Widgets/InputStyles.scss';

.edit-patient-info-modal {
  margin-bottom: 40px;

  .styled-dropdown {
    margin-bottom: 0;
  }

  .styled-input {
    margin-bottom: 0;
  }
  .styled-select {
    margin-bottom: 0rem;
  }

  .input-area .form-control {
    margin-bottom: 0;
  }

  [type='checkbox'].styled-checkbox:not(:checked) + label:before {
    top: 0;
  }
}
