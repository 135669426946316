@import 'scss/bootstrap-variables';
@import 'scss/mixins';

.patient-details-card {
  border-radius: 16px;
  border: solid 1px $gray-light;
  background-color: $white;

  .caretimer-container {
    z-index: 11;
  }

  .no-underline {
    text-decoration: none;
    overflow: hidden;
  }

  .details-container {
    position: relative;
    display: flex;
    margin-right: 0;
    margin-left: 0;
    align-items: stretch;
    padding: 40px 40px 48px 35px;

    .top-btns-container {
      position: absolute;
      top: -15px;
      left: 10px;
      padding-right: 20px;
      display: flex;
      width: 100%;
    }

    .profile-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-right: 32px;

      .patient-profile-img {
        background-size: cover;
        background-repeat: no-repeat;
        width: 97px;
        height: 97px;
        margin-bottom: 19px;

        &.male {
          background-image: url('~img/avatars/male_avater.svg');
        }

        &.female {
          background-image: url('~img/avatars/female_avatar.svg');
        }
      }

      .patient-edit-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        min-width: 70px;
        font-weight: 400;
        font-size: 14px;
        height: 31px;
      }
    }

    .buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 auto;
    }
  }
}

.option-subtitle {
  font-size: 12px;
  text-align: left;
}
