@import 'scss/mixins';
@import 'scss/bootstrap-variables';

.phone-input {
  .phone-label {
    font-size: 14px;
    font-weight: 500;
    color: $text-inactive;
    margin-bottom: 4px;
    line-height: 16px;

    &.error {
      color: $red;
    }
  }

  .purple-arrow-down {
    transition: transform 200ms;
    @include icon('down-arrow-purple.svg', 13px, 8px);
  }

  .phone-input-number-container {
    display: flex;
    align-items: center;
    position: relative;
    height: 45px;
    border: solid 1px $border;
    background-color: #ffffff;
    border-radius: 0 15px 15px 15px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.88;
    color: #000000;

    &.focused {
      border: 1px solid $primary-main;
    }

    .country-container {
      position: relative;
      height: 100%;
      padding: 0.375rem 0.75rem;

      .country-flag {
        width: 20px;
        height: 20px;
      }

      .countries-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        border: solid 1px $gray-light;
        background-color: #ffffff;
        border-radius: 0 0 15px 15px;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.88;
        color: #000000;
        padding: 0.375rem 0.75rem;

        .country-dropdown-item {
          display: flex;
          align-items: center;

          .country-text {
            margin: 0 0 0 1rem;
          }
        }
      }
    }

    .phone-number-text {
      font-weight: 400;
      line-height: 1.88;
      color: $text-primary;
      margin: 0 0 0 0.5rem;
      max-width: 140px;
      border: none;
      outline: none;
    }
    .error-text {
      position: absolute;
      display: none;
      pointer-events: none;
      align-items: center;
      top: 0;
      left: 4rem;
      bottom: 0;
      margin: 0;
      color: $red;
    }
    &.closed {
      .countries-dropdown {
        display: none;
      }
    }

    &.open {
      border-radius: 0 15px 15px 0;
      & .purple-arrow-down {
        transform: rotate(-180deg);
      }
    }

    &.valid.focused {
      border: 1px solid $primary-main;
    }

    &.invalid {
      border: solid 1px $red;
    }

    &.empty.focused {
      border: none;
      box-shadow: 0 0 0 0.1rem $blue-pastel-transparent;
    }

    &.disabled {
      background-color: $inactive-background;
      color: $text-inactive;
      ::placeholder {
        color: $text-inactive;
      }
      .phone-number-text {
        background-color: $inactive-background;
        color: $text-inactive;
      }
    }

    &.error {
      border: solid 1px $red;
      color: $red;

      .error-text {
        display: flex;
      }
    }
  }
  .phone-replace {
    color: $green-neon;
    height: 17px;
  }
}
