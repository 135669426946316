@import 'scss/bootstrap-variables';
$line-height: 30px;

.error-messages {
  text-align: center;
  color: $red;

  .error-message {
    min-height: 2 * $line-height;
    line-height: $line-height;
    font-style: italic;

    &:empty {
      min-height: 0;
    }
  }
}
