@import 'scss/bootstrap-variables';

.pathway-banner {
  border-radius: 8px;
  position: absolute;
  padding: 12px 20px 12px 16px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  z-index: 1;
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: 2px;
  white-space: nowrap;
  transition: all 300ms ease;
  background: #ffffff;
  box-shadow: 0px 3px 8px 0px $gray-light;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  &.point-based {
    padding-left: 97px;
    padding-right: 97px;
    width: auto;
    max-width: 305px;
  }
  .banner-tooltip {
    position: absolute;
    left: 25px;
    .trigger {
      display: flex;
      background: black;
      color: #ffffff;
      font-size: 12px;
      border-radius: 100%;
      text-align: center;
      height: 16px;
      width: 16px;
      padding-left: 3px;
      align-items: center;
      justify-content: center;
    }
  }
  &.emergency,
  &.warning {
    color: #ffffff;
    .banner-tooltip {
      .trigger {
        background: #ffffff;
      }
    }
  }
  &.emergency {
    background-color: $red;
    .banner-tooltip {
      .trigger {
        color: $red;
      }
    }
  }
  &.warning {
    background-color: $orange-warning;
    .banner-tooltip {
      .trigger {
        color: $orange-warning;
      }
    }
  }
  &.show {
    bottom: 170px;
  }
  &.hide {
    bottom: -100px;
  }
}
