@import 'scss/bootstrap-variables';

.ic-arrow {
  cursor: pointer;
  margin-left: 10px;
  color: #9b9b9b;
  transition: transform 200ms;
  &.selected {
    color: $primary-main;
  }
  &.up {
    transform: rotate(180deg);
  }
}
.header-col {
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 10px 10px 10px;
  &:first-child {
    padding-left: 25px;
  }
  &:last-child {
    padding-right: 25px;
  }
}
