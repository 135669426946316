@import 'colors.module';

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  color: $text-primary;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}
