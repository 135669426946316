@import 'scss/bootstrap-variables';

.styled-toggle-button {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  [type='checkbox'] {
    appearance: none;
    outline: none;
    cursor: pointer;
    position: relative;
    width: 50px;
    height: 25px;
    background-color: $gray-light;
    border-radius: 20px;

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 23px;
      height: 23px;
      background-color: white;
      border-radius: 50%;
      box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.1);
      transition-duration: 125ms;
    }

    &:checked {
      background-color: $primary-main;

      &:after {
        left: 26px;
      }
    }

    &:disabled:checked {
      cursor: initial;
      pointer-events: none;
      opacity: 0.6;
    }

    &:disabled:not(:checked) {
      cursor: initial;
      pointer-events: none;
      opacity: 0.6;
      background-color: $inactive-background;

      &:after {
        background-color: #eeeeef;
      }
    }
  }
  label.styled-toggle-button-label {
    margin: 0;
    padding-right: 10px;
    &.right {
      padding: 0 0 0 10px;
    }
  }
}
