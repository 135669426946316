.page-filters {
  .row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ticket-filters {
  margin-bottom: 40px;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  &.page-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;

    > * {
      flex: 0 0 191px;
    }

    &.shorten {
      > * {
        flex: 0 0 233px;
      }
    }

    &.full-width {
      > * {
        flex: 1;

        @media screen and (max-width: 900px) {
          flex: 0 0 200px;
        }
      }
    }
  }
}
