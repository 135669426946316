.window-body {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  overflow-y: scroll;
  .calls-list {
    flex-direction: column;
    display: flex;
    flex: 1;
    overflow-x: hidden;
    padding: 0.84rem;
    > .expandable-row-view {
      display: flex;
      flex-direction: column;
    }
  }

  .note-text-area {
    height: 100%;
    width: 100%;
    field-sizing: content;
    border: none;
    outline: none;
    font-size: 1rem;
  }

  .charge-codes {
    display: flex;
    flex-direction: column;
    .section {
      padding: 21px;
      width: 100%;
      .header {
        margin-bottom: 15px;
      }
      &:nth-child(even) {
        background-color: #f9fbff;
      }
    }
  }
}
