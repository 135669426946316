@import 'scss/bootstrap-variables';

.dashboard-view-container {
  height: 100%;

  .filters-row {
    margin-top: 32px;
    margin-bottom: 26px;
  }

  .expain-table .no-results {
    margin-top: 100px;
  }
}
