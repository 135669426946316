@import 'scss/bootstrap-variables';

.row-container {
  padding: 6px 0;

  &:not(.expanded) {
    height: 80px;
    .expain-row {
      align-items: center;
    }
  }
  .expain-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    border: solid 1px $gray-light;
    border-radius: 16px;
    transition: border-color ease-in-out 0.2s;
    background-color: $white;

    &.cursor-pointer:not(.disable) {
      cursor: pointer;
    }

    &:hover,
    &.selected {
      border: solid 1px $primary-main;
      text-decoration: none;
      color: inherit;

      .actions-container {
        visibility: visible;
        opacity: 1;
      }
    }
    &.disable {
      pointer-events: none;
      &:hover {
        border: solid 1px $gray-light;
      }
    }
    &.dashed {
      border-style: dashed;
    }

    .actions-container {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  }
}
