@import 'scss/bootstrap-variables';

.regimen-editor-modal {
  .regimen-form-container {
    padding-bottom: 40px;
  }

  .regimen-editor-row {
    > * {
      flex: 1;
      max-width: calc(100% - 38px);
    }

    &:hover {
      .editable-form-label,
      .icon-container {
        opacity: 0.8;
      }
    }

    &.editable {
      cursor: pointer;
    }

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }

  .days-row {
    .styled-input {
      flex: 1;
    }
    .styled-input:first-child {
      margin-right: 10px;
    }
  }

  .icon-container {
    max-width: 30px;

    &.editable {
      cursor: pointer;
    }

    &.field-visible {
      line-height: 45px;
    }
  }

  .editable-form-label {
    font-size: 14px;
    font-weight: bold;
    color: $primary-main;
  }
}
