@import 'scss/bootstrap-variables';

.actions-footer {
  display: flex;
  margin-top: 1.25rem;
  justify-content: space-between;

  .submit-section {
    display: flex;
    height: 40px;
  }
}
