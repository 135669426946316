.enrollment-manager {
  .enrollment-status-button {
    align-items: center;
  }

  .expanded {
    .enrollment-status-button {
      margin-top: 4px;
      max-height: 50px;
    }
  }

  .cm-report-mrn {
    font-size: 14px;
  }
}
