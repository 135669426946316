@import 'scss/bootstrap-variables';

.tickets-empty-container {
  font-size: 1.125rem;
  margin-top: 80px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;

  .empty-title {
    color: $gray;
  }
}
