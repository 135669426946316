.list-container {
  position: relative;
  .empty-view {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
