@import 'scss/bootstrap-variables';

.edit-info-inputs {
  .styled-multi-select {
    .selection-area-container {
      .Select-control {
        .Select-placeholder {
          color: $gray;
        }
      }
    }
  }
  .styled-dropdown {
    margin-bottom: 1.25rem;
  }
  .styled-select {
    margin-bottom: 1.25rem;
  }
  .styled-input {
    margin-bottom: 1.25rem;
  }

  .input-header-label {
    font-size: 0.85rem;
    font-weight: bold;
    color: $gray;
    margin-bottom: 5px;
  }

  .mrns-container {
    .mrn-inputs {
      display: flex;

      ::placeholder {
        color: $red;
      }

      &.disabled {
        .mrn-label-input {
          background-color: $gray-lighter;
          color: $gray;
        }
        .mrn-id-input {
          background-color: $gray-lighter;
          color: $gray;
        }
      }
      .mrn-label-input {
        position: relative;
        width: 100%;
        height: 45px;
        padding: 0.5rem 1rem;
        border: solid 1px $gray-light;
        border-bottom-left-radius: 16px;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.88;
        color: #000000;
      }
      .mrn-id-input {
        height: 45px;
        width: 100%;
        padding: 0.5rem 1rem;
        border: solid 1px $gray-light;
        border-radius: 0 16px 16px 16px;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.88;
        color: #000000;
        outline: none;

        &:focus {
          z-index: 1;
        }
      }
      &.error {
        .mrn-label-input {
          border: solid 1px $red;
          color: $red;
        }
        .mrn-id-input {
          border: solid 1px $red;
          color: $red;
        }
      }
    }
  }
}
