@import 'scss/bootstrap-variables';

.resettable-input {
  .input-header {
    font-size: 0.85rem;
    font-weight: bold;
    color: $gray;
    margin-bottom: 4px;
  }
  .input-area {
    position: relative;
    input {
      height: 45px;
      border: solid 1px $gray-light;
      border-radius: 0 15px 15px 15px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.88;
      color: $text-primary;
      margin-bottom: 1.25rem;

      &:not(:disabled) {
        background-color: #ffffff;
      }

      &.uneditable {
        background-color: $inactive-background;
        color: $text-inactive;
      }

      &.form-control:focus {
        border: 1px solid $primary-main;
        box-shadow: none;
      }
    }

    .error-text {
      position: absolute;
      background: transparent;
      pointer-events: none;
      display: none;
      align-items: center;
      left: 1rem;
      top: 0;
      bottom: 0;
      margin: 0;
      color: $red;
    }
  }

  &.error {
    .input-area {
      position: relative;
      input {
        border: solid 1px $red;
        color: $red;
      }
      .error-text {
        display: flex;
      }
    }
  }

  &.disabled {
    .input-area {
      input {
        background-color: $inactive-background;
        color: $text-inactive;
      }
    }
  }
}
