@import 'scss/bootstrap-variables';
@import 'scss/mixins';

.app {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.intro-mode {
    overflow-y: hidden;
  }

  &.wq {
    height: auto;
    min-height: 100vh;
  }
}
button:focus {
  outline: none;
}
* {
  font-family: Roboto, sans-serif;
}

.main {
  position: relative;
  padding-top: $header-height;
  flex: 1;
  background-color: $primary-contrast;

  input::placeholder {
    color: $gray;
  }
  .dashboard-view {
    padding-top: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  div.limited-width {
    max-width: 1250px;
    width: 90%;
    margin: 0 auto;
    display: block;
  }

  .wide-view {
    max-width: 1600px;
    width: 90%;
    margin: 0 auto;
  }

  .blue-white-table {
    border-radius: 16px;
    border: solid 1px $gray-light;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    margin-top: 1rem;
    .d-sm-table {
      border-collapse: separate;
      border-spacing: 0;
      font-size: 0.84rem;
      tr {
        th {
          padding-top: 0.875rem;
          padding-bottom: 0.875rem;
          padding-right: 0.875rem;
        }
        td {
          padding-top: 0.875rem;
          padding-bottom: 0.875rem;
          padding-right: 0.875rem;
          &:first-child {
            padding-left: 0.875rem;
            padding-right: 0;
            font-weight: bold;
          }
        }
      }
      tr:first-child th:first-child {
        padding-left: 0.875rem;
        padding-right: 0;
        border-top-left-radius: 16px;
        overflow: hidden;
      }
      tr:first-child th:last-child {
        border-top-right-radius: 16px;
        overflow: hidden;
      }
      tr:last-child td:first-child {
        border-bottom-left-radius: 16px;
        overflow: hidden;
      }
      tr:last-child td:last-child {
        border-bottom-right-radius: 16px;
        overflow: hidden;
      }
      thead tr,
      tbody tr:nth-child(even) {
        background-color: $gray-lighter;
      }
      tr {
        &:hover {
          background-color: #fbfbfb;
        }
      }
    }

    .pagination-container {
      width: min-content;
      height: 30px;
      position: absolute;
      right: 30px;
      bottom: -15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 16px;
      box-shadow: 0 1px 4px 0 #e0e2e4;
      background-color: #ffffff;
      .arrow-container {
        padding: 10px 16px;

        div.back-arrow {
          border-right: 5px solid black;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
        }
        div.forward-arrow {
          border-left: 5px solid black;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
        }
      }

      div.divider {
        width: 2px;
        height: 15px;
        border: solid 0.5px #dadada;
      }
    }
  }
}

.bouncy-animation {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
