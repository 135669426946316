.quick-addition-time {
  width: 58px;
  margin: 0 14px;
  color: #484848;
}

.quick-addition-dropdown-container {
  p {
    font-size: 13.5px;
    color: #484848;
  }
}
