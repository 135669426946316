@import 'colors.module';
// Bootstrap overrides

//
// Color system [DEPRECATED - use '_colors.scss' for adding/changing colors instead)
//

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #d1d4d7;
$gray-300: #818a91;
$gray-400: #869fac;
$gray-500: #7a7a7a;
$gray-600: #55595c;
$gray-700: #3e515b;
$gray-800: #2a2c36;
$gray-900: #151b1e;
$black: #000 !default;

$blue: #20a8d8;
$indigo: #6610f2 !default;
$light-purple: #f1eeff; // TODO -move to _colors.scss as color-primary-light
$pink: #e83e8c !default;
$orange: #f8cb00;
$orange-warning: #e37300;
$yellow: #ffc107 !default;
$green: $green-neon;
$teal: #20c997 !default;
$cyan: #63c2de;
$gray-label: #595959;
$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $primary-main,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
);

$theme-colors: (
  primary: $blue,
  secondary: $gray-300,
  success: $green,
  info: $cyan,
  warning: $orange,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: true;

// Body
//
// Account for the `<body>` element.

$body-bg: #fff;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;

// Breadcrumbs

$breadcrumb-padding-x: 2rem;
$breadcrumb-bg: transparent;
$breadcrumb-margin-bottom: 0rem;

// Cards

$card-border-color: $gray-200;
$card-cap-bg: $gray-100;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;

// Buttons

$btn-secondary-border: $gray-300;

// Progress bars

$progress-bg: $gray-100;

// Tables

$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;

// Forms

$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;

// General
$page-padding: 10%;
$header-height: 4rem;
$preview-warning-height: 60px;
