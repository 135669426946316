@import 'scss/mixins';
@import 'scss/bootstrap-variables';

.ticket-renderer {
  width: 100%;
  position: relative; /* anchor for checkbox-label */
  display: flex;
  flex-direction: column;
  padding: 20px 25px 20px 0;
  min-height: 100px;
  border-color: inherit;

  &.no-border {
    border-width: 0;
  }

  .bulk-checkbox-wrapper {
    display: flex;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-52%, -50%);
    border-color: $inactive-background;
  }

  .ticket-renderer-main {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &.urgent {
    border-radius: 16px;
    background-color: $alerts-light;
  }

  &.loading {
    position: relative;
    overflow: hidden;
  }

  &:hover {
    border-color: $primary-main;
  }

  &-patient-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 180px;
    line-height: 20px;

    .details-row {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // added to compensate for text cutting from the left (EH-4429)
      padding-left: 1px;
      .details-row {
        padding-left: 0;
      }
    }

    .patient-name {
      color: $primary-main;
      font-size: 16px;
      font-weight: 500;

      &.black {
        color: black;
      }
    }

    .patient-mrn {
      font-weight: normal;
      text-decoration: none;
      font-size: 14px;
      color: $text-primary;
    }

    .date-text {
      text-decoration: none;
      font-size: 14px;
      color: $text-inactive;
    }
  }

  &-summary {
    margin-right: 100px;
    line-height: 20px;
    margin-left: 40px;
    flex: 1;

    .title-text {
      font-size: 16px;
      font-weight: 500;
    }

    .subtitle-text {
      font-size: 16px;
      color: $text-inactive;
      font-weight: 400;
    }

    .description-text {
      font-size: 14px;
    }

    &.wide-summary {
      margin-right: 50px;

      & .symptom-name {
        font-weight: 500;
      }

      & .oral-report-row-title,
      & .callback-preference {
        font-weight: 500;
        font-size: 14px;
      }

      & .oral-report-date {
        color: $primary-main;
      }
    }
  }

  &-actions {
    margin-left: auto;

    .link-button {
      text-decoration: none;
    }

    .action-button:last-child {
      margin: 0;
    }

    .action-button,
    .status,
    .text-button,
    .reassign-text {
      font-size: 14px;
    }

    .status,
    .reassign-text {
      color: $text-primary;
      font-weight: 500;
    }
  }

  & .icon-wrapper {
    width: 64px;
  }
}
