@import 'scss/bootstrap-variables';

.call-reason-selection-modal {
  &.default-modal {
    border-radius: 0 0 16px 16px;
  }
}

.call-reasons-view {
  padding: 0 0 30px 0;
  margin-bottom: 20px;
}
