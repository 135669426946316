// New color palette
$primary-main: #007c66;
$primary-dark: #1c614c;
$primary-light: #afdcbe;
$primary-alternate: #e8f3f1;
$primary-contrast: #fbfbf9;
$primary-contrast-dark: #f6f6f1;

$secondary-main: #1a2b82;
$secondary-light-50: #4d60c9;
$secondary-light-25: #dee2fd;

$text-primary: #1e233e;
$text-dark: #000000;
$text-inactive: #676a7b;

$white: #ffffff;
$inactive-background: #f3f3f3;
$border: #e3e3e3;
$active-background: #b4e08a;
$leaf-50: #d7f3bd;
$leaf-25: #edf8e0;
$sunshine-50: #fff0a1;
$coral: #ff8b7e;

$alerts-main: #f75f32;
$alerts-dark: #e30000;
$alerts-light: #ffe8e3;

$warnings-main: #f89636;
$warnings-dark: #d5792c;
$warnings-alternate: #781e0e;

$mild-main: #80cb6e;
$mild-dark: #529100;

$high-risk-tag: #ffc2b5;
$episode-tag-1: #b4e08a;
$episode-tag-2: #ffd90e;
$episode-tag-3: #4d60c9;
$episode-tag-4: #ffac7e;
$episode-tag-5: #37ae9a;
$episode-tag-6: #ce569e;
$episode-tag-7: #496f5d;
$episode-tag-8: #317ace;
$episode-tag-9: #542344;
$episode-tag-10: #895cd3;

$primary-hover: #eff1f2;

// TODO: cleanup variables after this comment

// primary
$primary-light-color: #ece8ff;

$gray-lighter: #f5f8fa; // Very Light Backgrounds
$gray-light: #e3e3e3; // Inactive Button/Field Background
$gray: #7a7a7a; // Inactive Button/Field Text
$gray-dark: #484848; // Dark Grey Text

$red: #e30000;

$green-neon: #5bd800;
// $green-pastel: #80cb6e; // used symptom table

$blue-light: #4a90e2;
$blue-pastel-transparent: rgba(32, 168, 216, 0.25);

:export {
  primary: $primary-main;
  primary-light: $primary-light-color;
  grayLighter: $gray-lighter;
  grayLight: $gray-light;
  gray: $gray;
  grayDark: $gray-dark;
  red: $red;
  greenNeon: $green-neon;
  blueLight: $blue-light;
  bluePastelTransparent: $blue-pastel-transparent;
}
