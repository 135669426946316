@import 'scss/bootstrap-variables';

.property-item {
  display: flex;
  font-size: 14px;
  line-height: 20px;

  &-label {
    font-weight: 500;
    margin-right: 4px;
    white-space: nowrap;
  }
}

.property-item + .property-item {
  margin-top: 4px;
}
