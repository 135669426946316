@import 'scss/bootstrap-variables';

.symptom-fields-container {
  margin-bottom: 20px;

  .inner-modal-block {
    padding-top: 10px;
  }

  .ending-section {
    margin-top: 20px;
  }
}
