@import 'scss/colors.module';

.calls-tab {
  .section-title {
    font-size: 18px;
  }

  .calls-tab-section {
    padding-bottom: 40px;
    .collapse.show {
      margin-top: 40px;
    }
  }

  .empty-state {
    font-size: 24px;
    color: $text-inactive;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
  }
}
