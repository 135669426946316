@import 'scss/bootstrap-variables';

.stack {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;

  &:focus-within {
    // prevent hiding mentions-suggestions menu by other tickets
    z-index: 2;
  }

  &:before {
    content: '';
    display: block;
    width: 7px;
    background-color: $inactive-background;
    position: absolute;
    top: 0;
    bottom: 10px;
    left: 60px;
    z-index: -1;
  }

  .fake-stacked-element {
    border: 1px solid $inactive-background;
    box-shadow: 0 1px 4px #e0e2e4;
    border-radius: 16px;
  }

  .stacked-element,
  .fake-stacked-element {
    width: calc(100% - 20px);
    align-self: flex-end;

    &:first-child {
      width: 100%;
      z-index: 0;

      &:focus-within {
        // prevent hiding mentions-suggestions menu by other tickets
        z-index: 1;
      }
    }
  }

  &.stacked {
    .stacked-element {
      margin-bottom: 1.25rem;
    }
    &.closed {
      .stacked-element {
        margin-bottom: 0.5rem;
      }
    }
  }

  &.open {
    .stacked-element:last-child {
      margin-bottom: 0;
    }
  }

  &.closed {
    margin-bottom: calc(1rem + 8px);

    .stacked-element {
      width: 100%;
      align-self: center;
      z-index: 1;
    }

    .fake-stacked-element {
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      background-color: $primary-contrast-dark;

      &:nth-child(2) {
        top: 0;
        width: calc(100% - 20px);
        z-index: 0;
      }

      &:nth-child(3) {
        top: 8px;
        width: calc(100% - 40px);
        z-index: -1;
      }
    }
  }
}
