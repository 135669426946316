@import 'scss/bootstrap-variables';

.edit-patient-info-modal,
.invitation-page {
  .styled-input,
  .resettable-input,
  .styled-dropdown,
  .styled-select,
  .phone-label {
    &.error {
      .input-header,
      .dropdown-label {
        color: $red;
      }
    }
  }
  .landline-option-disable {
    color: $gray;
    display: flex;
    flex-direction: column;
    &::after {
      content: 'Unavailable for landline phones.';
      font-size: 13.5px;
    }
  }
}
