@import 'bootstrap';
@import 'scss/bootstrap-variables';

.analytics-dashboard-page {
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 50px;
  }

  .live-boards-toggle {
    width: fit-content;
    margin-bottom: 40px;
  }

  .tab-container {
    flex: 1;
    display: none;

    &.active {
      display: block;
    }
  }
}
