@import 'scss/bootstrap-variables';
.pathway-with-alert-container {
  display: flex;
  align-items: baseline;
  column-gap: 25px;

  & .pathway-name {
    max-width: 106px;
  }

  & .alerts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & .alert-container {
      max-width: 135px;
    }
  }
  .warning-container {
    margin-left: auto;
  }
}
