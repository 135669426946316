.cell {
  padding: 20px 10px;
  &.remove-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    height: 100%;
  }
  &:first-child {
    padding-left: 25px;
  }
  &:last-child {
    padding-right: 25px;
  }
}
