@import 'scss/bootstrap-variables';

.combo-picker-container {
  &.combo-border-primary {
    border-color: $primary-main;
  }
  // rw classes used to override 3rd party style
  &.rw-widget-picker.rw-widget-container {
    width: 152px;
    box-shadow: none;
    border: 1px solid $gray-light;

    input {
      font-size: 0.84rem;
      padding: 0;
      text-align: center;
      cursor: initial;
      color: $gray-dark;
      box-shadow: initial;
    }
  }
}

.rw-datetime-picker {
  .rw-list-option.rw-state-selected,
  .rw-list-option.rw-state-selected:hover,
  .rw-cell.rw-state-selected,
  .rw-cell.rw-state-selected:hover,
  .rw-list-option.rw-state-focus,
  .rw-list-option.rw-state-focus:hover {
    background-color: $primary-main;
    border-color: $primary-main;
    color: #ffffff;
  }

  .rw-btn {
    border: 0;
    vertical-align: initial;
  }

  .rw-select-bordered {
    border-left: 1px solid $gray-light;
  }

  &.rw-state-focus {
    .rw-widget-picker {
      border: $gray-light 2px solid;
    }
  }
  .interval-date-picker {
    &:hover {
      background-color: transparent;
      .rw-btn-select {
        opacity: 0.75;
      }
    }
  }
  .rw-select {
    &:hover {
      background-color: transparent;
      .rw-btn-select {
        opacity: 0.75;
      }
    }
    &:active {
      box-shadow: none;
    }
    .rw-btn-select {
      &:hover {
        background-color: $gray-light;
        opacity: 1;
      }
      // separator between date and time buttons
      &:nth-child(2) {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          width: 1px;
          height: 100%;
          background-color: $gray-light;
          top: 0;
        }
      }
    }
  }
}
