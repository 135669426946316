@import 'scss/colors.module';

.doctor-details {
  .general-data {
    display: flex;
    flex-direction: row;

    .details {
      display: flex;
      align-items: center;
      color: $text-primary;
    }

    .doctor-name {
      font-weight: bold;
      font-size: 1.5rem;
    }
    .institution-name {
      font-size: 1.5rem;
      margin-left: 0.3rem;
    }

    .departments {
      font-size: 1rem;
      color: $text-inactive;
      font-weight: 500;
    }
  }
}
