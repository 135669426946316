.name-cell {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
}

.expanded .name-cell {
  max-height: 50px;
  margin-top: 4px;
}
