$tooltip-radius: 6px;

.tippy-box {
  border-radius: $tooltip-radius;

  .tippy-content {
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
