@import 'scss/colors.module';

.text-button {
  color: $secondary-main;
  font-size: 16px;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  &:disabled {
    color: $text-inactive;
    pointer-events: none;
  }
}
