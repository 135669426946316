.optout-checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-right: 1rem;

  [type='checkbox'].styled-checkbox + label {
    align-self: auto;
    padding-left: 30px;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    cursor: pointer;
  }
}
