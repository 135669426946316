@import 'scss/colors.module';

.regimen-summary-container {
  position: relative;
  width: 100%;

  .regimen-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 13.5px;
    color: $text-inactive;
    overflow-y: auto;
    height: 106px;
    width: 100%;
    margin: 4px 0 0;
    border: solid 1px #e3e3e3;
    background-color: $inactive-background;
    padding: 12px 100px 12px 20px;
    border-radius: 0 16px 16px 16px;
  }
  .customize-button {
    font-size: 13.5px;
    color: $primary-main;
    position: absolute;
    top: 60px;
    right: 20px;
    cursor: pointer;
    padding: 5px;
  }
}
