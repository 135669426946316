@import 'scss/bootstrap-variables';
@import 'scss/mixins';
@import 'bootstrap';

header.header {
  height: $header-height;
  padding: 11px 40px;
  display: flex;
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 1px 4px 0 #e0e2e4;

  .header-dropdown {
    width: 175px;
    visibility: hidden;
    position: absolute;
    text-transform: none;
    right: 0;
    top: 30px;

    &.open {
      visibility: visible;
      box-shadow: 0 1px 4px 0 #e0e2e4;
      border: solid 1px $gray-light;
      background-color: #ffffff;
    }

    .divider {
      height: 1px;
      border: solid 0.5px $gray-light;
      left: 0;
      right: 0;
    }

    .header-dropdown-item {
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 10;
      color: $text-primary;
      @extend .py-2;
      @extend .px-3;

      i.logout {
        @include icon('logout-dark.svg', 13px, 12px);
      }

      &:hover {
        background-color: $primary-hover;
      }

      &.disabled {
        color: $text-inactive;

        &:hover {
          color: $text-inactive;
          background-color: initial;
        }
      }
    }
  }

  .header-search-wrapper {
    margin: 0 20px;
  }

  .menu-item {
    color: $text-primary;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    transition: 0.4s ease;
    margin-left: 40px;

    &.account-item {
      margin-left: 26px;
    }

    &:hover,
    &.active,
    .active {
      > a,
      p {
        color: $primary-main;
      }
      color: $primary-main;
    }

    a,
    p {
      margin: 0;
      display: block;
      text-decoration: none;
      color: $text-primary;
    }
  }

  .menu-item ~ .divider {
    width: 2px;
    height: 20px;
    border: solid 1px #d8d8d8;
    margin-left: 3.125rem;
  }
}
