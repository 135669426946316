@import 'scss/bootstrap-variables';

.toggle-container {
  font-size: 14px;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-radius: 45px;
  border: 0.5px solid $inactive-background;
  background-color: $primary-contrast-dark;

  &.large-toggle-container {
    height: 45px;
  }

  &.small-toggle-container {
    height: 28px;
  }

  &.medium-toggle-container {
    height: 35px;
  }

  &.squared,
  &.squared .toggle-item.active {
    border-radius: 9px;
  }

  .toggle-item {
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    justify-content: center;
    user-select: none;
    box-sizing: content-box;
    font-weight: normal;

    .toggle-text {
      white-space: nowrap;
      margin: 0;
    }

    &.disabled {
      pointer-events: none;

      .toggle-text {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    &.active {
      margin: 0;
      height: 100%;
      padding-left: 15px;
      padding-right: 15px;
      border: 1px solid #eeeeee;
      font-weight: 500;
      border-radius: 40px;
      box-shadow: 0 1px 4px #e0e2e4;
      background-color: $white;
    }
  }
}
