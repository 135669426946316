@import 'scss/bootstrap-variables';

.send-text-modal {
  .modal-content {
    padding: 30px;
    border-radius: 16px;
  }

  .header {
    font-size: 24px;
    line-height: 30px;
  }

  .subtitle {
    font-size: 0.84rem;
    font-weight: bold;
    text-align: center;
    color: #6f6f6f;
    margin-top: 5px;
    line-height: 14px;
  }

  .warning {
    color: $red;
    font-size: 14px;
    margin-bottom: 32px;
  }

  .footer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
  }

  .message-content {
    border-radius: 9px;
    height: 142px;
    color: $text-primary;
  }

  .content-label {
    font-weight: 500;
    color: $gray-dark;
  }

  .input-wrapper {
    position: relative;

    textarea.form-control:focus {
      border: 1px solid $primary-main;
      box-shadow: none;
      color: $text-primary;
    }
  }

  .char-count {
    position: absolute;
    color: $text-inactive;
    right: 18px;
    bottom: 10px;
    font-size: 14px;
    font-weight: bold;
  }
}
