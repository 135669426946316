@import 'scss/bootstrap-variables';
@import 'scss/animations';

.practice-manager-episodes-page {
  position: relative;
  .episode-row {
    margin-bottom: 62.5px;
  }

  .collapsible-section-header {
    margin-bottom: 40px;
  }

  .episode-actions {
    display: flex;
  }

  .task-template-row {
    .task-template-details {
      width: 0;
    }
    .ticket-renderer-alert-badge {
      width: 0;
      margin: 0;
    }
  }

  .enroll-episode-trigger {
    animation: shake 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    cursor: pointer;
    color: $primary-main;
  }
}
.templates-loader {
  position: relative;
  min-height: 50px;
}
.create-edit-episode-form {
  margin: 0 0 28px 0;
  position: relative;

  .errors-container {
    padding-top: 20px;
  }

  .error-messages {
    text-align: center;
    width: 100%;
    color: $red;
    padding-top: 10px;
    font-style: italic;
  }
}
