@import 'scss/bootstrap-variables';

.quick-addition-seperator-container {
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: white;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: -10px;
  padding: 0 10px;
  .quick-addition-text {
    font-size: 13.5px;
    font-weight: bold;
    margin-right: 6px;
  }
}

.entries-editor {
  width: 100%;
  margin: 10px 0 40px 0;
  .entry-editor-header-row {
    text-align: center;
    margin: 0 16px 10px 0;

    .entry-header {
      background-color: $gray-lighter;
      border-radius: 12px;
      padding: 4px 0;
    }
  }
  .entry-editor-row {
    text-align: center;
    padding: 10px 0;
    margin-left: 0;
    margin-right: 0;
    border: 1px solid transparent;
    border-radius: 16px;

    .addition-btn {
      display: none;
    }

    .addition-duration {
      color: $gray-500;
      align-self: center;
    }
    .addition-reason {
      text-align: left;
    }
    &:hover,
    &.is-new {
      .addition-btn {
        display: initial;
      }
    }

    .remove-interval-btn {
      align-items: center;
      justify-content: center;
      display: none;
    }

    &:hover {
      .remove-interval-btn {
        display: flex;
      }
      background: $primary-alternate;
    }

    &.highlight {
      border-color: $red;
    }
  }

  .entry-error-list {
    color: $red;
    margin-top: 10px;
    text-align: left;

    ul {
      margin-left: 16px;
      padding: 0;
      list-style-type: disc;
      list-style-position: inside;
    }
  }
  .save {
    button {
      margin: 0 auto;
      min-width: 120px;
      font-weight: bold;
    }
  }
  .gray-text {
    color: $gray-500;
  }
}
