@import 'scss/mixins';
@import 'scss/bootstrap-variables';

.ticket-row-icon-container {
  cursor: default;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border-radius: 16px 0 0 16px;

  .ticket-row-icon {
    position: relative;
    .ticket-row-icon-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 12px;
    }

    .exclamation-dark {
      color: $text-primary;
    }
    .exclamation-red {
      color: $red;
    }
    .circle-badge {
      width: 10px;
      height: 10px;
      min-width: 10px;
      border-radius: 50%;
    }

    .mild {
      background-color: $mild-main;
    }

    .standard-operator,
    .callback {
      background-color: $text-primary;
    }

    .high-symptom {
      background-color: $alerts-main;
    }
  }
}
