@import 'scss/bootstrap-variables';

.patient-info-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;

  .name-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.48;
      letter-spacing: 0.5px;
    }
  }

  .info-container {
    display: flex;
    flex: 1;

    .info-column {
      display: flex;
      flex-direction: column;
    }

    .info-column + .info-column {
      margin-left: 50px;
    }

    .info-column:last-child {
      flex: 1;
    }
  }
}
