@import 'scss/bootstrap-variables';

.keyboard-shortcut-info-pane {
  position: absolute;
  left: 100%;
  top: 0;
  width: fit-content;

  margin: -10px 0 0 20px;

  padding: 20px;

  font-size: 13.5px;
  color: $gray;
  line-height: normal;

  & span {
    font-weight: bold;
  }
}
