.ticket-pathways-answers .pathway-questions-answers-container {
  margin-top: 8px;
}

.ticket-pathways-answers .pathway-questions-answers-container .pathway-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 2px;
}

.ticket-pathways-answers
  .pathway-questions-answers-container
  .pathway-question-answer:not(:last-of-type) {
  margin-bottom: 4px;
}

.ticket-pathways-answers
  .pathway-questions-answers-container
  .pathway-question-answer
  .question-header {
  min-height: 18px;
}

.ticket-pathways-answers
  .pathway-questions-answers-container
  .pathway-question-answer
  .question-header
  .question-alert {
  display: inline-block;
}

.ticket-pathways-answers
  .pathway-questions-answers-container
  .pathway-question-answer
  .question-header
  .question-alert
  svg {
  height: 16px;
  width: 16px;
  margin-left: 8px;
}

.ticket-pathways-answers
  .pathway-questions-answers-container
  .pathway-question-answer
  .question-header
  .question-alert.immediate-attention
  svg {
  color: #e30000;
}

.ticket-pathways-answers
  .pathway-questions-answers-container
  .pathway-question-answer
  .question-header
  .question-alert.attention-today
  svg {
  color: #f89636;
}

.ticket-pathways-answers
  .pathway-questions-answers-container
  .pathway-question-answer
  .question-header
  .question-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.ticket-pathways-answers
  .pathway-questions-answers-container
  .pathway-question-answer
  .answers-container {
  padding-left: 12px;
}

.ticket-pathways-answers
  .pathway-questions-answers-container
  .pathway-question-answer
  .answers-container
  .denied-option {
  display: flex;
}

.ticket-pathways-answers
  .pathway-questions-answers-container
  .pathway-question-answer
  .answers-container
  .denied-option
  .prefix {
  font-weight: 400;
  font-size: 14px;
  margin-right: 2px;
}
