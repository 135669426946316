@import 'scss/bootstrap-variables';

.date-form-option {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  width: 100%;
  .label {
    margin: auto;
    font-weight: bold;
    position: absolute;
    left: 8px;
    z-index: 1;
    font-size: 1rem;
  }
  .styled-input {
    flex-grow: 1;
    input {
      border-radius: 0;
      padding-left: 85px;
    }
    &.error + .label {
      color: $red;
    }
  }
}
