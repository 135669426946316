.scheduled-protocol-modal {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 40px;

  .base-modal-footer {
    padding: 0 40px;
    margin-top: 100px;
  }

  .toggle-bar-wrapper {
    margin-bottom: 24px;
    position: relative;
    &:before {
      content: '';
      height: 1px;
      background-color: #e3e3e3;
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .protocol-toggle-bar {
      max-width: 75%;
      margin: 0 auto;
      position: relative;
      .toggle-item {
        flex: 1;
      }

      div:has(> .toggle-item) {
        flex: 1;
      }
    }
  }
}
