@import 'bootstrap';
@import 'scss/bootstrap-variables';

.patient-tags {
  width: 100%;
  height: 100%;
  .tags-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 4px 0;
  }
  .more-tags {
    color: $text-inactive;
    font-size: 14px;
  }
  .truncate-chip-item {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}
.tooltip-tag {
  @extend .mb-2;
  @extend .mr-0;
  align-self: flex-start;
  word-break: break-word;
  &:last-child {
    @extend .mb-0;
  }
}
