@import 'scss/bootstrap-variables';

.base-modal {
  border-radius: 16px;
  border: solid 1px $gray-light;
  background-color: #ffffff;
  position: relative;

  .modal-content {
    border-radius: 16px;
    margin: auto;
    align-self: center;
    border: none;
  }

  .base-modal-header {
    margin-bottom: 20px;
  }

  .base-modal-title {
    font-size: 24px;
    color: black;
    line-height: 30px;
    margin: 0;
    font-weight: 600;

    &.center {
      text-align: center;
    }
  }

  .base-modal-subtitle {
    font-size: 14px;
    color: black;
    margin: 3px 0 0 0;
    line-height: 14px;

    &.subtitle-warn {
      color: $red;
    }
  }

  .base-modal-footer {
    display: flex;
    align-items: center;

    .action-btn {
      cursor: pointer;
      text-align: center;
      min-width: 120px;
      padding: 0.5rem 0;
      color: $red;
      box-shadow: none;
      border: 1px solid $inactive-background;

      &:hover {
        background-color: $primary-hover;
      }
    }
  }
}
