@import 'scss/bootstrap-variables';

.practice-manager-page {
  &.dashboard-view.limited-width {
    display: flex;
  }
  .title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .practice-manager-page-tabs-selector {
    width: fit-content;
    margin-top: 43px;
    margin-bottom: 48px;
    .practice-manager-page-selector-item {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .page-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 55px;

    > * {
      flex: 1 0 233px;
    }
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .top-tab-section {
    position: absolute;
    top: -65px;
    right: 0;
  }
  .no-results {
    display: flex;
    justify-content: center;
    margin: 150px 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: $gray;
  }
}
