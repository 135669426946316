@import 'scss/bootstrap-variables';

.care-timer-intro-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  .explenation-row {
    margin-bottom: 8px;
    font-size: 14px;
  }
}
