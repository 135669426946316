@import 'scss/bootstrap-variables';
@import 'scss/animations';

.patient-episodes-and-tasks {
  .episodes-list-section {
    &.episodes-list-bottom-space {
      padding-bottom: 20px;
    }
    .section-title {
      font-weight: 500;
      font-size: 18px;
    }
  }

  .task-filters {
    display: flex;
    margin: 60px 0 30px;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    > * {
      flex: 0 0 191px;
    }
  }
  .no-episodes {
    display: flex;
    justify-content: center;
    margin: 150px 0;
    font-size: 1.5rem;
    font-weight: bold;
    .enroll-episode-trigger {
      animation: shake 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      cursor: pointer;
      color: $primary-main;
    }
  }
}
