@import 'scss/bootstrap-variables';
@import 'views/Widgets/InputStyles';
@import 'scss/bootstrap-variables';

.invitation-page {
  max-width: 900px;
  padding: 40px 1rem 1rem;
  width: 100%;
  margin: 0 auto;
  display: block;

  .bottom-button-text-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    h5 {
      margin-left: 1rem;
      &.error {
        color: $red;
      }
    }
  }

  .step-section-container {
    counter-reset: number;
    counter-increment: number;
    > .row {
      &:not(:nth-last-child(2)) {
        margin-bottom: 5rem;
      }
      counter-increment: number;

      .section-number::before {
        content: counter(number);
      }
    }
  }

  .row {
    .col-1 {
      .section-number {
        width: 30px;
        height: 30px;
        color: white;
        text-align: center;
        padding-top: 0.05rem;
        font-size: 1.125rem;
        font-weight: bold;
        background: $primary-main;
        border-radius: 50%;
      }
    }

    &:first-child {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      .optout-checkbox-wrapper {
        label {
          font-size: 16px;
        }
      }
    }
  }

  .fade-in {
    transition: opacity 1s ease-out;
    opacity: 1;
  }

  .fade-out {
    opacity: 0.4;
    transition: opacity 1s ease-out;
    pointer-events: none;
  }

  .invitation-subtitle {
    font-size: 1.13rem;
    margin-bottom: 0rem;
  }

  .invitation-title {
    font-size: 1.5rem;
    font-weight: 900;
    align-self: flex-start;
    margin-bottom: 2rem;

    &.first-title {
      margin-left: -1rem;
    }
  }

  .input-header-label {
    font-size: 0.85rem;
    font-weight: bold;
    color: $text-inactive;
    margin-bottom: 5px;
  }

  .page-title {
    display: flex;
    align-items: center;
    padding-top: 90px;
    margin-bottom: 40px;
    justify-content: space-between;
  }

  .searchbar-container {
    margin-bottom: 5rem;
  }

  .message-content-container {
    margin-bottom: 1.25rem;

    .message-content-text-area {
      width: 100%;
      padding: 1rem;
      box-shadow: 0 1px 4px 0 #e0e2e4;
      border: solid 1px $gray-light;
      background-color: #ffffff;
      border-radius: 0 16px 16px 16px;

      &:disabled {
        background-color: $gray-lighter;
        color: $text-inactive;
      }
    }
  }

  .consent-row {
    & .link {
      color: $primary-main;
    }
  }

  .invite-patient-text {
    cursor: pointer;
    margin: 0;
  }

  .bottom-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-right: 15px;
    margin-top: 2rem;
  }

  #activateProtocol {
    position: relative;
    top: -23px;
    font-size: 0.9rem;

    label {
      cursor: pointer;
      display: flex;
      align-items: center;

      > div {
        margin-left: 5px;

        > span:first-child {
          font-weight: 500;
        }
      }
    }

    .italic {
      font-style: italic;
    }

    &.disabled {
      color: gray;
    }
  }
}
