@import 'scss/bootstrap-variables';

.styled-fields-wrapper {
  .large {
    textarea {
      border-radius: 0 15px 0 0;
    }
  }
  margin-bottom: 57px;
  .styled-additional-fields {
    .fields-row {
      margin-top: 0;
      display: flex;

      .field {
        flex: 1;
        margin: 0;
        // temp class - until StyledFieldForm removed (create ticket form re-design)
        .MuiTypography-root.MuiTypography-body1 {
          display: none;
        }
        .input-header {
          display: none;
        }
        .styled-input.error .input-area input {
          border: solid 1px $gray-light !important;
        }

        .styled-select__control {
          border-radius: 0;
        }
        .inner-label-select {
          display: flex;
        }
      }

      .field + .field {
        margin-left: 0;
      }
      &:last-child {
        .field:first-child {
          input,
          .styled-select__control {
            border-bottom-left-radius: 15px;
          }

          .dropdown-head-container {
            border-radius: 0 0 0 15px;
          }
        }
        .field:last-child {
          input,
          .styled-select__control {
            border-bottom-right-radius: 15px;
          }
          .dropdown-head-container {
            border-radius: 0 0 15px 0;
          }
        }

        .field .styled-dropdown.open .dropdown-head-container {
          border-radius: 0;
        }
      }
    }
  }
}
