@import 'scss/bootstrap-variables';

.ticket-row.report-row-closed {
  margin-bottom: 24px;
}

.highlighted-title {
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1.75px;
  color: $secondary-light-50;
  margin-bottom: 10px;
}
