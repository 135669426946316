.frequency-fields-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  .frequency-multiplier-container {
    width: 60px;
  }
  .frequency-container {
    width: 130px;
  }
  .start-time-container {
    width: 140px;
  }
  .styled-select {
    .styled-select__control {
      border-radius: 16px;
    }
  }
}
.block {
  padding: 20px 0;
}
