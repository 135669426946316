@import 'bootstrap';
@import './Tooltip';
@import 'scss/bootstrap-variables';

.tooltip-select {
  font-size: 1rem;

  .dropdown-search {
    display: flex;
    align-items: center;
    border-bottom: solid 1px $gray-light;

    .search-input {
      border-style: none;
      outline: none;
    }
  }
  cursor: default;

  .tooltip-select-option {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    padding: 8px 28px 8px 10px;
    &:first-child {
      margin-top: 10px;
    }

    &:last-child {
      border: none;
      margin-bottom: 10px;
    }

    &:hover:not(.readonly):not(.disabled),
    &:hover.selected,
    &:hover .extra,
    &:hover .edit-interval-intro,
    &:hover .keywords {
      background-color: $primary-hover;
    }

    &.selected {
      color: $primary-main;

      .checkmark {
        display: flex;
        align-items: center;
        visibility: visible;
      }

      &:not(:hover) {
        .option-subtitle {
          color: initial;
        }
      }

      &.secondary {
        color: $secondary-light-50;
      }
    }

    &.disabled {
      cursor: default;
      &:not(.selected) {
        color: $text-inactive;
      }
    }

    &.readonly {
      cursor: text;
    }

    .checkmark {
      margin-right: 4px;
      visibility: hidden;
    }
    .option-subtitle {
      font-size: 12px;
      text-align: left;
    }

    .extra {
      font-size: 13.5px;
      color: $gray-500;
    }
    &.with-divider {
      $divider-spacer: 20px;
      position: relative;

      &.divider-placement-top {
        margin-top: $divider-spacer;
      }

      &.divider-placement-bottom {
        margin-bottom: $divider-spacer;
      }

      &.divider-placement-top:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        top: -$divider-spacer;
        border-top: 1px solid $gray-light;
        margin: $divider-spacer * 0.5 0;
      }

      &.divider-placement-bottom:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -$divider-spacer;
        border-bottom: 1px solid $gray-light;
        margin: $divider-spacer * 0.5 0;
      }
    }
  }

  .options-container {
    max-height: 300px; // TODO: replace with theme.menu.maxHeight
    overflow: auto;
    &.with-header {
      // for options with header - skip top-radius for the first child
      .tooltip-select-option:first-child {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
    &.with-footer {
      // for options with footer - skip bottom-radius for the last child
      .tooltip-select-option:last-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .tooltip-select-checkbox-option {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:not(.disabled) {
      label,
      input {
        cursor: pointer;
      }
    }
    label {
      margin: 0;
    }
  }

  .tooltip-select-title {
    font-weight: bold;
    color: $primary-main;
  }
}
