@import 'scss/bootstrap-variables';

.weekday-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  .day-picker-title {
    margin-right: 1.6rem;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .day-option {
    width: 30px;
    height: 30px;
    margin-right: 0.625rem;
    border: none;
    align-items: center;
    justify-content: center;
    background-color: white;
    display: flex;
    cursor: pointer;

    &.error {
      color: $red;
    }

    &.selected {
      border-radius: 16px;
      background-color: $primary-main;
      color: white;
    }
  }
}
