@import 'scss/bootstrap-variables';
@import 'scss/mixins';

.window-footer {
  position: relative;
  background-color: $primary-contrast;
  padding: 20px;

  .save-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .primary-symptom-btn {
      border: 2px solid $primary-main;
      color: $primary-main;
      border-radius: 30px;
      padding: 8px 20px;
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      &.error {
        color: $red;
        border: 2px solid $red;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .btns-container {
    margin-right: auto;
    display: flex;
    flex-direction: row;
    .btn-container {
      display: none;
      font-size: 0.875rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 500ms, transform 500ms;
      .timer-options {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        span {
          width: 100%;
          padding: 7px 0;
          text-align: center;
          font-size: 0.875rem;
          border-bottom: 1px solid black;
          &:last-child {
            border: none;
          }
          &:first-child {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
          }
          &:last-child {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          }
          &:hover {
            background-color: lightgrey;
          }
        }
      }
      .documentation-btn {
        transform: translateY(400px);
        &.red {
          border: none;
          color: white;
          background-color: $red;
          input {
            background-color: transparent;
          }
          & ~ .label {
            color: $red;
          }
        }
        i {
          margin-right: 0.5rem;
        }
      }
      &:first-child {
        margin-right: 13px;
      }
      &.show {
        display: flex;
        opacity: 1;
        .documentation-btn {
          transform: translateX(0);
        }
      }
      .label {
        display: flex;
        justify-content: center;
        font-size: 0.84rem;
        font-weight: bolder;
        color: $gray;
      }

      .footer-btn-container {
        &.purple-on-hover:hover {
          color: $primary-main;
          .footer-btn {
            color: inherit;
            border: 2px solid $primary-main;
          }
          .label {
            color: inherit;
          }
        }
        .footer-btn {
          height: 35px;
          width: 118px;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: space-around;
          border: 2px solid $text-inactive;
          border-radius: 20px;
          padding: 4px 10px;
          box-shadow: 0 1px 4px 0 #e0e2e4;
          color: $text-inactive;

          &.green {
            border: none;
            background-color: $primary-main;
            color: $white;

            &:hover {
              background-color: $primary-dark;
            }
          }

          &.red {
            border: none;
            background-color: $red;
            color: white;
            & ~ .label {
              color: $red;
            }
          }
          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 1rem;
            letter-spacing: 0.8px;
          }
        }
      }
    }
  }
  .footer-initiate {
    cursor: pointer;
    display: flex;
    align-items: center;
    p {
      font-weight: 500;
    }
  }
}
