$transition-duration: 300ms;

// assumption: default (no rotation) equal to icon down rotation
.icon-with-rotation {
  transition: transform $transition-duration;
  &.up {
    transform: rotate(180deg);
  }
  &.right {
    transform: rotate(-90deg);
  }
  &.left {
    transform: rotate(90deg);
  }
}
