@import 'scss/bootstrap-variables';
@import 'scss/mixins';

$icon-size: 20px;
.stacked-element {
  .call-row {
    border: none;

    &:first-child {
      margin-top: 0;
    }

    & .call-row-title {
      font-weight: 500;
    }
  }
}
.call-row {
  font-size: 16px;

  .call-row-title {
    font-weight: 500;
  }

  .secondary-text {
    font-weight: 400;
    color: $text-inactive;
  }

  .call-icon {
    background-size: contain;
    background-position-x: center;
    min-width: $icon-size;
  }
}
