.collapsible-section {
  .collapsible-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .collapsible-section-trigger {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;

      h4 {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
