.account-details-container {
  margin-bottom: 28px;

  .styled-input {
    margin-bottom: 1.25rem;
  }

  .styled-multi-select {
    .Select-control {
      border-top-left-radius: 0;
    }
  }
}
