@import 'scss/bootstrap-variables';

$footer-color: $gray-dark;

.footer {
  font-size: 12px;
  color: $secondary-main;
  font-weight: 500;
  display: flex;
  justify-content: center;
  background-color: $primary-contrast;

  .footer-links {
    display: flex;
    & a {
      color: $footer-color;
      &:hover {
        color: $primary-main;
      }
    }
  }
}
