@import 'bootstrap';
@import 'scss/mixins';
@import 'scss/bootstrap-variables';

.call-logging-wrapper {
  position: fixed;
  right: 0;
  top: $header-height;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 5;

  .call-logging-container {
    position: fixed;
    right: 0;
    top: $header-height;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 5;

    .call-view {
      position: relative;
      z-index: 1;
      width: 700px;
      min-width: 700px;
      box-shadow: -3px 0 8px 0 rgba(221, 221, 221, 0.5);
      border: solid 1px #dedfe0;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .loading-backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(#fff, 0.5);
        z-index: 10;
      }

      p {
        margin: 0;
      }

      .answers-counter-text {
        color: $gray;
      }

      .popup-screen {
        position: absolute;
        height: 100%;

        .popup-container {
          max-width: 376px;
        }

        &.edit-duration-modal {
          align-items: start;

          .popup-container {
            max-width: 100%;
            padding: 21px;
            border-top-left-radius: 0;
          }
        }
      }
    }

    &.minimized {
      top: auto;
      right: 2rem;
      bottom: 2rem;
      left: auto;
      height: auto;
      width: auto;

      .pathway-view-container {
        display: none;
      }

      .call-view {
        min-width: auto;
        width: auto;
        border-radius: 16px;

        &.active-call {
          background-color: $green-neon;
        }
      }
    }

    &.is-preview-mode {
      top: calc($header-height + $preview-warning-height);
      padding-bottom: $preview-warning-height;
    }
  }
}
