.tag-item-container {
  margin: 2px;
  user-select: none;
  display: block;

  &.chip.chip-default {
    background-color: transparent;
  }

  .tag-item-truncate {
    max-width: 180px;
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
  }
}

.tag-tooltip-container {
  padding: 20px;
  border-radius: 6px;

  ul {
    padding-left: 20px;
    margin: 0;
  }
}
