@import 'bootstrap';
@import 'src/scss/bootstrap-variables';

.edit-reason-modal-content {
  margin: 0 0 40px;
}

.opt-out-form {
  margin: 0 0 20px;
  > div {
    padding-bottom: 20px;
  }
}

#saveCustomReason {
  position: absolute;
  left: 0;
  bottom: 4px;
  padding-bottom: 0;
  font-weight: bold;
  font-size: 1rem;
}
