@import 'scss/bootstrap-variables';

.manage-practice-users-page {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  .deactivate-subtitle {
    max-width: 320px;
  }
  .doctor-credentials {
    color: $gray-dark;
  }
}
