@import 'scss/bootstrap-variables';
@import 'scss/mixins';

.care-timer-intro-bg {
  background-color: $black;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 11;
  margin-top: -$header-height;
}

.patient-main-view {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .content-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    .empty-content-text {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      margin-bottom: 0;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: $gray;
    }
  }
  .patient-main-content {
    padding-top: 3.625rem;
  }

  .patients-page-header {
    position: relative;
    margin-bottom: 4rem;

    .patients-page-tabs-selector {
      position: absolute;
      bottom: -13px;
      left: 0;
      right: 0;
      margin: auto;
      width: fit-content;

      &-item {
        width: 150px;
      }
    }
  }

  .content-section {
    &:not(.active) {
      display: none;
    }
  }

  h2.section-title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #000000;
    margin-bottom: 1.5rem;
  }

  .symptoms-chart-section {
    margin-bottom: 0.2rem;
    padding: 20px 0;
    justify-content: space-between;
    .collapsible-section-header {
      margin-bottom: 40px;
    }
    .symptoms-chart-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      cursor: pointer;
    }

    .symptoms-toggle {
      border-radius: 16px;
    }
  }

  .call-saved-container {
    position: fixed;
    bottom: 100px;
    visibility: hidden;
    right: -500px;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 1px 4px 0 #e0e2e4;
    border: solid 1px $gray-light;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    transition: right 700ms 0ms;

    &.open {
      right: 100px;
      visibility: visible;
    }
  }
}
