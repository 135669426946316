@import 'scss/bootstrap-variables';

.tickets-call-connect-modal {
  height: 75vh;

  @media (max-height: 1080px) {
    height: 70vh;
  }

  @media (max-height: 800px) {
    height: 65vh;
  }

  .connect-tickets-to-calls-container {
    height: 90%;
    overflow-y: auto;
  }

  .collapsible-section {
    margin-bottom: 40px;
  }
  .call-row {
    // When inside the modal we do not allow to edit the call
    .edit-call-tickets-button {
      display: none;
    }

    // When inside the modal we do not allow to resume draft
    .resume-call-button {
      display: none;
    }
  }

  .call-reasons-view {
    margin-right: -24px;
    margin-left: -24px;
    padding-top: 0;
    margin-bottom: 0;
    width: 100%;

    &:before {
      content: '';
      height: 1px;
      background: $gray-light;
      width: 100%;
      margin-bottom: 25px;
    }
    &.row {
      margin-right: 0;
      margin-left: 0;
    }
    .col {
      max-width: 50%;
      &:first-child {
        padding-left: 23px;
      }
      &:last-child {
        padding-right: 23px;
      }
    }
  }

  .connected-toggle-container {
    display: flex;
    align-items: center;

    label {
      font-size: 13px;
      font-weight: bold;
      color: $gray-dark;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
}

.connect-ticket-transition-enter {
  opacity: 0.01;
}

.connect-ticket-transition-enter-active {
  opacity: 1;
  transition: all 300ms ease-in;
  transition-delay: 300ms;
}

.connect-ticket-transition-exit {
  opacity: 1;
}

.connect-ticket-transition-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-in;
}
