.oral-protocol-fields {
  .section {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    .styled-select {
      width: 100%;
    }
    .styled-input {
      width: 100%;
    }
  }
}
.information-text {
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
