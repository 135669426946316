@import 'scss/animations';
@import 'scss/bootstrap-variables';

.report-protocol-select-container {
  min-width: 0;
  .tooltip-container {
    position: absolute;
    top: 1.6rem;
    left: 0;
    bottom: 0;
    right: 5.2rem;

    .tooltip-text.bottom {
      min-width: 16rem;
      text-align: center;

      .protocol-dropdown-option {
        &:hover {
          transition: 0.3s;
          background-color: $primary-main;
          color: white;

          &:first-child {
            border-radius: 0.7rem 0.7rem 0 0;
          }

          &:last-child {
            border-radius: 0 0 0.7rem 0.7rem;
          }
        }

        font-size: 1rem;

        .label {
          padding: 0.7rem;
        }

        .divider {
          border-top: solid 0.3px $gray-light;
          left: 0;
          right: 0;
        }
      }
    }
  }
}
