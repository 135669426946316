@import 'scss/bootstrap-variables';

.practice-providers-page {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .searchbar {
    margin-bottom: 20px;
  }
}
