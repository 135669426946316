@import 'scss/bootstrap-variables';

.divider-message {
  background-color: #f3f3f3;
  position: relative;
  left: 0;
  right: 0;
  padding: 0.5rem 2rem;
  margin: 10px 0 40px 0;
  font-size: 0.84rem;
  font-style: italic;
  text-align: center;
  border-radius: 16px;
  p {
    margin: 0;
  }
  .explanation-text {
    color: $text-inactive;
  }
  .warning-text {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    color: $red;
  }
}
