@import 'bootstrap';
@import 'scss/bootstrap-variables';

.care-management-page {
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 50px;
  }
  .care-toggle {
    width: fit-content;
    margin-bottom: 40px;
  }
  .enrollment-manager,
  .enrolled-patients {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .page-filters {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 20px;
      margin: 30px 0 10px 0;

      > * {
        flex: 1 0 160px;
      }

      .styled-select .styled-select__multi-value__label {
        // keep muli-select filters in a single line. see https://expain.atlassian.net/browse/EH-3722
        max-width: 130px;
      }
    }
    .expain-table {
      .header-col {
        font-size: 0.9rem;
      }
      .list-container {
        font-size: 14px;
      }

      .name-cell {
        .patient-link {
          color: $primary-main;
          font-size: 14px;
          font-weight: 500;
        }
        &:hover .patient-link {
          text-decoration: underline;
        }
      }
    }
  }
}

// temp solution till full responsive design available
@media only screen and (max-width: 1280px) {
  .wide-view {
    min-width: 1250px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

// temp solution till full responsive design available
@media only screen and (max-width: 1440px) {
  .care-management-page {
    .enrollment-manager {
      .page-filters {
        > * {
          flex: 0 0 160px;
        }
      }
    }
  }
}
