@import 'scss/bootstrap-variables';

.input-header {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: $text-inactive;
  margin-bottom: 4px;
  line-height: 16px;

  .header-text {
    flex: 1;
  }

  .error-text {
    font-style: italic;
  }
}
