@import 'scss/bootstrap-variables';

.pathway-table-of-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  .pathway-content-search {
    margin-bottom: 20px;
    width: 100%;
  }
  .category-content {
    margin-bottom: 20px;
  }
  .question {
    margin: 8px 0;
    cursor: pointer;

    &.active {
      font-weight: bolder;
      color: $primary-main;
    }
  }
}
