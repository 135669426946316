@import 'scss/bootstrap-variables';
@import 'scss/mixins';

$border-radius: 20px;
$disabled-color: #bfbfbf; // TODO: replace with gray-light after icon color support add (so text+chevron can be gray)

.split-button {
  // this style taken from .small-button and call-logger
  // common button styles should be export to a shared file
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  .split-button-main,
  .split-button-chevron {
    padding: 8px 15px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $primary-main;
    color: $white;
    font-weight: 500;
    border: none;

    &:enabled {
      cursor: pointer;
    }

    &:disabled {
      color: #00000060;
      background-color: #f3f3f3;
      pointer-events: none;
    }
  }

  .split-button-main {
    border-radius: $border-radius 0 0 $border-radius;
    padding-right: 10px;
    border-right: 1px solid $white;
    width: 100px;

    &,
    &:disabled {
      // splite button separator
      border-right-color: #e3e3e3;
    }

    &:hover {
      background-color: $primary-dark;
    }
  }

  .split-button-chevron {
    border-radius: 0 $border-radius $border-radius 0;
    padding-left: 10px;
    border-left: none;

    i.icon-arrow {
      @include icon('white-chevron.svg', 13px, 9px);
    }

    &:hover {
      background-color: $primary-dark;
    }
  }
}
