@import 'scss/bootstrap-variables';

.horizontal-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: rgba(#fff, 0.5);
  z-index: 10;

  .lds-ellipsis div {
    background: rgba($primary-main, 0.8);
  }
}
