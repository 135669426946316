@import 'bootstrap';
@import 'scss/bootstrap-variables';

.expandable-cell {
  word-wrap: break-word;
  height: 100%;
  font-size: 14px;
  .multi-line {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: initial;
  }
}

.diagnoses-cell {
  ul {
    @extend .pl-4;
  }
}

.no-diagnosis {
  color: $text-inactive;
}

.dx-type-title {
  font-weight: 500;
}
