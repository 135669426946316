@import 'scss/bootstrap-variables';
@import 'scss/mixins';

.reports-table {
  margin-bottom: 4rem;
  position: relative;

  .empty-state-table {
    width: 100%;
    display: flex;
    padding-top: 50px;
    align-items: center;
    justify-content: center;

    .empty-state-text {
      font-size: 1.125rem;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  .table-container {
    position: relative;

    .table {
      thead {
        tr {
          th {
            &.top-left-corner-cell {
              border-color: transparent;
            }
            &.th-severity {
              border-color: transparent;
              display: table-cell;
              text-align: center;
              vertical-align: center;
              font-size: 0.85rem;
              position: relative;
              &.triage {
                &::after {
                  content: 'Triage Call';
                  font-size: 10.5px;
                  text-align: center;
                  position: absolute;
                  bottom: -3px;
                  left: -1px;
                  right: 0;
                  font-weight: normal;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
      tbody {
        tr.section-header {
          font-size: 12px;
          color: $gray;
          &:hover {
            background-color: transparent;
          }
          td:first-child {
            padding-right: 0;
            display: flex;
          }
          td:nth-child(2) {
            padding-left: 0;
          }
          .separator {
            position: relative;
            top: 8px;
            height: 1px;
            width: 100%;
            background-color: #a2a2a2;
          }
        }
        tr {
          // for distress thermometer (can't see the numbers color in light gray)
          &:first-child {
            td.td-severity .content.color-0 {
              background-color: #dfe2e6;
            }
          }
          &:hover {
            td:first-child {
              border-bottom-left-radius: 16px;
              border-top-left-radius: 16px;
            }
          }
          td {
            border: none;
          }
          td:first-child {
            white-space: nowrap;
            font-weight: bold;
            font-size: 0.85rem;
            vertical-align: middle;
          }

          td.td-severity {
            color: white;
            font-weight: bold;
            font-size: 12px;
            text-align: center;
            vertical-align: middle;
            .content {
              color: white;
              border-radius: 50%;
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: auto;
              margin-right: auto;
              background-color: #dfe2e6;
              &.rectangle {
                border-radius: 0.25rem;
                width: 36px;
              }
              &.opaque {
                opacity: 0.4;
              }
              .black {
                color: black;
              }
              &.color-0 {
                background-color: #f2f3f5;
              }
              &.color-1 {
                background-color: #80cb6e;
              }
              &.color-2 {
                background-color: #f89636;
              }
              &.color-3 {
                background-color: #f75f32;
              }
              &.color-4 {
                background-color: #781e0e;
              }
              &.color-5 {
                background-color: $primary-main;
              }
            }
          }
        }
      }
    }
  }
}
