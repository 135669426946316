@import 'scss/bootstrap-variables';

.base-card {
  position: relative;
  display: flex;
  border-radius: 16px;
  border: solid 1px $border;
  transition: border-color 0.2s ease-in-out;
  background-color: white;

  &.dashed-border {
    border: 2px dashed $inactive-background;
    border-radius: 16px;
    background-color: transparent;
  }

  &:hover,
  &.highlight {
    border-color: $primary-main;

    & .ticket-renderer {
      & .bulk-checkbox-wrapper {
        & input + div {
          border-color: $primary-main;
        }

        span.Mui-checked {
          div {
            border-color: $primary-main;
          }

          &:hover {
            div {
              border-color: $primary-dark;
            }
          }
        }
      }
    }
    [type='checkbox'].styled-checkbox {
      &.inherit-border-color {
        &:checked,
        &:not(:checked) {
          + label,
          + label:before {
            transition: border-color 0.2s ease-in-out;
            border-color: $primary-main;
          }
        }
      }
    }
  }
}

.card-row {
  margin-bottom: 1rem;
  min-height: 104px;
  &.no-min-height {
    min-height: auto;
  }
}
