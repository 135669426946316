@import 'scss/bootstrap-variables';

.styled-dropdown {
  .landline-option-disable {
    color: $gray;
    display: flex;
    flex-direction: column;
    &.dropdown-option {
      align-items: flex-start;
      padding-left: 28px;
    }
    .option-checkmark {
      display: none;
    }

    &::after {
      content: 'Unavailable for landline phones.';
      font-size: 13.5px;
    }
  }
}
